import React from 'react'
import Helmet from 'react-helmet'

export default props => <Helmet
  htmlAttributes={{ lang : 'ja' }}
  title={(props.title ? props.title + ' | ' : '') + 'DrupalCamp DEN 2019 Nagoya'}
  meta={[
    { name: 'google-site-verification', content: 'H5p26Tfyi7RLtaRwmXUd-yAxAHlZgtKBzdJnkgWiZUU' },
    { name: 'description', content: '2019年12月7日、名古屋でDrupalCamp DEN 2019 Nagoyaを開催。' },
    { name: 'viewport',
      content:'width=device-width, initial-scale=1,maximum-scale=1,minimum-scale=1, user-scalable=no',
    },
    { httpEquiv: 'content-language', content: 'ja' },
    { property: 'og:title', content: 'DrupalCamp DEN 2019 Nagoya' },
    { property: 'og:type', content: 'website' },
    { property: 'og:description', content: '2019年12月7日、DrupalCamp DEN 2019 Nagoyaを開催。' },
    { property: 'og:url', content: 'http://drupal-camp2019.den-japan.org' },
    { property: 'og:site_name', content: 'DrupalCamp DEN 2019 Nagoya' },
    { property: 'og:image', content: 'https://den-japan.org/sites/default/files/2019-11/Drupal_camp_Banner.png' },
  ]}
  link={[
      { rel: 'shortcut icon', type: 'image/png', href: 'https://den-japan.org/sites/default/files/den_logo_fav1.png' }
  ]}
/>
