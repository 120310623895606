import React from 'react'
import {Inner} from '../components/responsive/'
import Entry from '../components/entry'

export default props => <div>
  <Inner
    color='#0098cb'
    size='1.5em'
    marginTop='2em'
    bold
    center
  >
  DrupalCamp DEN 2019 Nagoya<br/>
  2019年12月7日(土)
  </Inner>
  <Inner
    color='#0098cb'
    size='1.2em'
    marginTop='0.5em'
    paddingBottom='2em'
    bold
    center
  >
  〒451-0042 愛知県名古屋市西区那古野２丁目１４−１
  </Inner>
  <Entry/>
</div>
